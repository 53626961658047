@font-face {
    font-family: 'Magisk';
    src: url('./fonts/Magisk.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Handskrevet';
    src: url('./fonts/Handskrevet.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Tøff';
    src: url('./fonts/Tøff.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Tegnefilm';
    src: url('./fonts/Tegnefilm.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Comic Sans';
    src: url('./fonts/Fresh.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Barnebok';
    src: url('./fonts/Barnebok.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Stjernekrig';
    src: url('./fonts/Stjernekrig.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
