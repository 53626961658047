.App {
  text-align: center;
}

.header-content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}

.banner-content {
  background-color: palegreen;
}

.banner-content h2 {
  font-family: 'Courier New';
  font-size: 50px;
}

.banner-content p {
  font-family: 'Courier New';
  font-size: 50px;
}

.logo img {
  height: 180px;
}

.media {
  display: flex;
  align-items:flex-end;
  justify-content: center;
  color: black;
  text-decoration: none;
  font-size: large;
  font-weight: 600;
}

.media img {
  width: 36px;
  height: 36px;
  margin: 12px;
}

header h1 {
  margin: 12px;
}

.menu-content {
  margin-top: 12px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  padding: 12px;
  width: 100%;
  background-color: whitesmoke;
  border: 1px solid grey;
}

.menu-button {
  width: 150px;
  margin: 3px;
  padding: 12px;
  border-radius: 12px;
  background-color: #48CFC8;
  color: black;
  text-decoration: none;
  font-size: large;
  font-weight: 600;
  transition: background-color 0.3s, color 0.3s;
}

main {
  padding: 12px;
}

.main-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.card {
  display: flex;
  flex-wrap: wrap-reverse;
  flex-direction: row;
  justify-content: space-between;
  margin: 12px;
  border: 1px solid grey;
  border-radius: 12px;
  padding: 12px;
  width: 22%;
  background-color: whitesmoke;
}

.carousel-container {
  width: 55%;
  margin: 12px;
}

.card-content {
  padding: 12px;
  width: 35%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-content > div:nth-child() {
  flex-grow: 1;
}

.card-lone-content {
  padding: 12px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-lone-content > div:nth-child() {
  flex-grow: 1;
}

.card-button {
  width: 150px;
  margin: 6px auto;
  padding: 12px;
  border-radius: 12px;
  background-color: #48CFC8;
  color: black;
  text-decoration: none;
  font-size: large;
  font-weight: 600;
  transition: background-color 0.3s, color 0.3s;
}

.card-button:hover {
  background-color: #3BB8AC;
  color: whitesmoke;
}

.primary-button {
  margin: 6px;
  padding: 12px;
  border-radius: 12px;
  border-color: transparent;
  background-color: #48CFC8;
  color: black;
  text-decoration: none;
  font-size: large;
  font-weight: 600;
  transition: background-color 0.3s, color 0.3s;
}

.secondary-button {
  margin: 6px auto;
  padding: 12px;
  border-radius: 12px;
  background-color: whitesmoke;
  color: black;
  text-decoration: none;
  font-size: large;
  font-weight: 600;
  transition: background-color 0.3s, color 0.3s;
}

.secondary-small-button {
  margin: 6px auto;
  padding: 6px;
  border-radius: 12px;
  background-color: whitesmoke;
  color: black;
  text-decoration: none;
  font-size: medium;
  font-weight: 500;
  transition: background-color 0.3s, color 0.3s;
}

.konfigurator-card {
  margin: 12px;
  border: 1px solid grey;
  border-radius: 12px;
  padding: 12px;
  background-color: whitesmoke;
  margin: 12px;
  font-size: large;
}

.konfigurator-tekst {
  margin: 12px;
  font-size: large;
}

.konfigurator-tittel {
  margin: 12px;
  font-size: large;
  font-weight: bold;
}

.konfigurator-divider {
  margin: 24px 12px ;
}

.konfigurator-fargeboks {
  margin: 12px;
  max-width: 720px;
  align-self: center;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
}

.picture-content {
  margin-top: 12px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  padding: 12px;
  gap: 20px;
  width: 100%;
}

.handlekurv {
  margin: 12px;
  font-size: large;
}

footer {
  background-color: whitesmoke;
  padding: 12px;
  border: 1px solid grey;
}

@media (max-width: 2800px) {
  .card {
    width: 45%;
  }
}

@media (max-width: 1400px) {
  .card {
    width: 90%;
  }
}

@media (max-width: 750px) {
  .header-content {
    justify-content: center;
  }

  .banner-content h2 {
    font-size: 25px;
  }

  .banner-content p {
    font-size: 25px;
  }

  .card-content {
    width: 90%;
  }
  
  .carousel-container {
    width: 90%;
  }

  .close-button {
    position: absolute;
    top: 10px; /* Adjust the top position as needed */
    right: 10px; /* Adjust the right position as needed */
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    color: #000; /* Adjust the color as needed */
    outline: none;
  }
}

.no-bullets {
  list-style-type: none;
  padding-left: 0; 
}

@media only screen and (max-width: 600px) {
  .color-button {
    width: 120px; /* Reduserer bredden på knappen for mindre skjermer */
    font-size: 14px; /* Reduserer fontstørrelsen for mindre skjermer */
  }
}